/* ------------------------------
        layout
 ----------------------------- */
.button {border: 0px;}
.current-data {width: 30%;}
.filter {margin: 0 0 0 10px;}
.row {margin: 5px 0 0 0;}
 
#current-data {height: 50px; display: flex; align-items: center;}
#filter {height: 60px; align-items: center; display:flex;}
#header {height: 55px; display:flex; align-items: center; justify-content: center;}
#refresh {text-align: center;}
#report {margin: 10px 0 0 0;}
#tm-combine {max-height:200px;}
        #tm-combine td {text-align: right;}
        #tm-combine th {text-align: right;}

#tm-combine table tbody {
  max-height: 150px;
  overflow-y: scroll;
}

#tm-combine table thead tr th:first-of-type{
  width: 105px !important;
}

#tm-combine table tbody tr td:first-of-type{
  width: 75px !important;
}
/* ------------------------------
        typography
 ----------------------------- */
.goal-label {font-size: 24px;}
.goal-value {font-size: 24px; font-weight: 800}

#calling_time-ratio {font-weight: 800;}
#current-data {font-size: 24px;font-weight: 200;}
#header {font-size: 30px; font-weight: 600;}
#report {font-size: 18px;}
#tm-combine {font-size: 14px;}


/* ------------------------------
        color
 ----------------------------- */
body {background: #F5F5F5 !important;}

.button {background-color: #2F3541; color: #F5F5F5;}
    .button:hover{background-color: rgba(101, 101, 101, 0.7); color: #2F3541;}


#header {background-color: #E30B3D; color: white;}

.goal-label { font-size: 20px; }

.sub-button {color: white !important;}

.tm_report{
    display: flex;
    align-items: center;
    flex-direction: column;
}

#refresh-btn{
    color: white !important;
}

#download-monthly{
    color: white !important;
}